<template>
  <div class="dropzone-container">
    <div class="files" v-show="!!files.length">
      <DropzoneFile
        v-for="file in files"
        :key="`file-${file.upload.uuid}`"
        :file="file"
        @removeFile="removeFile"
      />
    </div>
    <vue-dropzone
      id="dropzone"
      v-show="!files.length"
      :ref="ref"
      :options="dropzoneOptions"
      :multipleUpload="false"
      :useCustomSlot="true"
      @vdropzone-files-added="filesChange"
      :class="{ disabled }"
    >
      <slot />
    </vue-dropzone>
  </div>
</template>
<script>
import vueDropzone from 'vue2-dropzone'
import DropzoneFile from './DropzoneFile.vue'

export default {
  components: {
    vueDropzone,
    DropzoneFile
  },
  data() {
    return {
      ref: `dropzone-${new Date().getTime()}`,
      dropzoneOptions: {},
      files: []
    }
  },
  props: {
    value: {
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.dropzoneOptions = {
      ...this.options,
      url: process.env.VUE_APP_NEW_API,
      autoProcessQueue: false,
      init: function() {}
    }
  },
  methods: {
    getRef() {
      return this.$refs[this.ref]
    },
    filesChange(files) {
      const filesExtensions = Array.from(files).map(
        file => '.' + file.name.split('.').pop()
      )

      const invalidFiles = filesExtensions.filter(
        file => !this.dropzoneOptions.acceptedFiles.split(',').includes(file)
      )

      if (invalidFiles.length) {
        this.toast(`you can't upload ${invalidFiles.join(', ')} file`, {
          type: 'error'
        })
        this.reset()
        return
      }

      if (files.length > this.dropzoneOptions.maxFiles) {
        console.log('max files')
        this.toast(
          `you can't upload more than ${this.dropzoneOptions.maxFiles} file`,
          { type: 'error' }
        )
        this.reset()
        return
      }
      this.files = files
      this.$emit('input', files)
    },
    removeFile(file) {
      this.getRef().removeFile(file)
      this.files = this.getRef().dropzone.files
      this.$emit('input', this.files)
    },
    reset() {
      setTimeout(() => {
        this.getRef().removeAllFiles()
        this.files = []
        this.$emit('input', [])
      }, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.file-row {
  background-color: #eee;
  gap: 0.5rem;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
